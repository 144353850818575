import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import { Link } from "react-router-dom";
import {
  createBlogSection,
  deleteBlogSection,
  fetchBlog,
  fetchBlogProperty,
  fetchBlogSections,
  updateBlog,
  updateBlogSection,
} from "../../../helpers/services/admin.services";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toast from "react-hot-toast";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import UploadBlog from "./modals/UploadBlog";
import { Modal } from "react-bootstrap";

const EditBlog = () => {
  const { id } = useParams();

  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editorContent, setEditorContent] = useState("");
  const [sections, setSections] = useState([]);
  const [sectionImage, setSectionImage] = useState("");
  const [editingSection, setEditingSection] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [lastDisplay, setLastDisplay] = useState(1);
  const [sectionLoader, setSectionLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allProperties, setAllProperties] = useState([]);
  const [openUpdateBlog, setOpenUpdateBlog] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [deleteSectionId, setSDeleteSectionId] = useState(null);
  const [updateLoding, setUpdateLoading] = useState(false);

  //Update Fields
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [status, setStatus] = useState("");

  const fetchblog = async () => {
    setLoading(true);
    try {
      const response = await fetchBlog(id);
      if (response.status == "success") {
        setdata(response.data);
        setTitle(response.data.title);
        setSlug(response.data.slug);
        setDescription(response.data.description);
        setImageUrl(response.data.image_url);
        setStatus(response.data.status);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchAllProperties = async () => {
    try {
      const response = await fetchBlogProperty();
      if (response.data) {
        const properties = response.data;
        const convertedproperties = properties.map((property) => ({
          label: property.property_name,
          value: property.id,
        }));
        setAllProperties((preAllProp) => [
          ...preAllProp,
          ...convertedproperties,
        ]);
      }
    } catch (error) {
      console.error("Error fetching Properties:", error);
    }
  };

  const handlePropertyChange = (selectedOption) => {
    setSelectedProperty(selectedOption);
  };

  const fetchAllSection = async () => {
    setSectionLoader(true);
    try {
      const response = await fetchBlogSections(id);

      if (response.status == "success") {
        setSections(response.data);
        setLastDisplay(
          Number(response.data[response.data.length - 1].display_sequence)
        );
      }
    } catch (error) {
      console.log("Error Fetching secctions", error);
    } finally {
      setSectionLoader(false);
    }
  };

  const clearField = () => {
    setEditingSection(null);
    setSectionImage("");
    setEditorContent("");
    setSelectedProperty([]);
  };

  const handleAddSection = async (content, sectionType) => {
    if (editingSection !== null) {
      const updatedSections = { ...sections[editingSection] };
      updatedSections.section_data_mapping = content;
      const response = await updateBlogSection(
        updatedSections?.id,
        updatedSections
      );
      if (response.status == "success") {
        clearField();
        fetchAllSection();
        toast.success("Section Edited");
      } else {
        toast.error(response.errors);
      }
    } else {
      const newSection = {
        blog_id: id,
        section_data_mapping: content,
        display_sequence: lastDisplay + 1,
        section_type: sectionType,
      };
      createSections(newSection);
    }
  };

  const createSections = async (data) => {
    setButtonLoader(true);
    try {
      const response = await createBlogSection(data);

      if (response.status == "success") {
        toast.success("Section Added Successfully");
        setLastDisplay(lastDisplay + 1);
        fetchAllSection();
        setEditorContent("");
        setSectionImage("");
      } else {
        toast.error(response?.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoader(false);
    }
  };

  const addSectionImage = () => {
    handleAddSection(sectionImage, "image");
    setSectionImage("");
  };

  const handleEditSection = (index) => {
    const section = sections[index];
    if (section.section_type === "editor") {
      setEditorContent(section?.section_data_mapping);
    }
    if (section.section_type === "image") {
      setSectionImage(section?.section_data_mapping);
    }
    if (section.section_type === "advertisement") {
      console.log(section?.section_data_mapping);
      const convertedproperties = section?.section_data_mapping.map(
        (property) => ({
          label: property.property_name,
          value: property.id,
        })
      );
      setSelectedProperty(convertedproperties);
    }
    setEditingSection(index);
  };

  const handleDeleteSection = async () => {
    setUpdateLoading(true)
    try {
      const response = await deleteBlogSection(deleteSectionId);

      if (response?.status == "success") {
        setSDeleteSectionId(null);
        setDeleteModal(false);
        fetchAllSection();
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "insertTable",
        "|",
      ],
    },
  };

  const addPropertyAdvirtisment = () => {
    const content = selectedProperty.map((property) => property.value);
    handleAddSection(content, "advertisement");
    setSelectedProperty([]);
  };

  const handleUpdateBlog = async () => {
    const data = {
      title: title,
      slug: slug,
      description: description,
      image_url: imageUrl,
      status: status,
    };

    setUpdateLoading(true)

    try {
      const response = await updateBlog(id, data);

      if (response.status == "success") {
        fetchblog();
        fetchAllSection();
        fetchAllProperties();
        toast.success("Blog updated");
      } else {
        toast.error(response?.data?.errors);
      }
    } catch (error) {
      console.log(error);
    }finally{
      setUpdateLoading(false)
    }
  };

  useEffect(() => {
    fetchblog();
    fetchAllSection();
    fetchAllProperties();
  }, [openUpdateBlog]);

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/website/blog"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Website</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="/website/blog">Blog</a>
                <span>/</span>
              </li>
              <li>Edit </li>
            </ul>
          </div>
        </div>
        {data && (
          <div>
            <button
              className="basic-button"
              onClick={() => setOpenUpdateBlog(!openUpdateBlog)}
            >
              Update Blog Details
            </button>
          </div>
        )}
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center h-75">
          <div className="loader"></div>
        </div>
      )}

      {!loading && !data && (
        <div className="d-flex justify-content-center align-items-center h-75">
          <div>No data found </div>
        </div>
      )}

      {!loading && (
        <>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="mb-3 form_control_box">
                <label>
                  <b>Add Editor content</b>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={editorContent}
                  config={editorConfiguration}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorContent(data);
                  }}
                />
              </div>
              <div className="mb-3 form_control_box">
                <label>
                  <b>Add Section Image</b>
                </label>
                <input
                  type="text"
                  name="section_image"
                  placeholder="Section Image URL"
                  value={sectionImage}
                  onChange={(e) => setSectionImage(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-3 form_control_box">
                <label>
                  <b>Add Properties for Sdvertisement </b>
                </label>
                <Select
                  options={allProperties}
                  isMulti
                  value={selectedProperty}
                  onChange={(e) => handlePropertyChange(e)}
                  className="mb-3"
                  placeholder="Select the properties"
                />
              </div>
              {editorContent && (
                <button
                  type="button"
                  onClick={() => handleAddSection(editorContent, "editor")}
                  className="basic-button me-2"
                  disabled={buttonLoader}
                >
                  <div className="d-flex">
                    {buttonLoader && <div className="loader-small"></div>}
                    <div>
                      {editingSection !== null
                        ? "Update Section"
                        : "Add Section"}
                    </div>
                  </div>
                </button>
              )}
              {sectionImage && (
                <button
                  type="button"
                  onClick={addSectionImage}
                  className="basic-button mx-3"
                >
                  {editingSection !== null
                    ? "Update Section"
                    : "Add Section Image"}
                </button>
              )}
              {selectedProperty.length > 0 && (
                <button
                  type="button"
                  onClick={addPropertyAdvirtisment}
                  className="basic-button mt-4"
                >
                  {editingSection !== null
                    ? "Update Section"
                    : "Add Property advertisement"}
                </button>
              )}

              <div className="text-center my-5">
                {" "}
                <h6>
                  <b>OR</b>
                </h6>{" "}
              </div>

              <>
                <div className="">
                  {" "}
                  <h6>
                    <b>Update Blog</b>
                  </h6>{" "}
                </div>
                <div className="mb-3 form_control_box">
                  <label>
                    <b>Title</b>
                  </label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 form_control_box">
                  <label>
                    <b>Slug</b>
                  </label>
                  <input
                    type="text"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 form_control_box">
                  <label>
                    <b>Description</b>
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control"
                  ></textarea>
                </div>
                <div className="mb-3 form_control_box">
                  <label>
                    <b>Image URL</b>
                  </label>
                  <input
                    type="text"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 form_control_box">
                  <label>
                    <b>Status</b>
                  </label>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="form-control"
                  >
                    <option value="draft">Draft</option>
                    <option value="published">Published</option>
                  </select>
                </div>
                <div>
                  <button
                    className="basic-button d-flex"
                    disabled={updateLoding}
                    onClick={handleUpdateBlog}
                  >
                    {updateLoding && <div className="loader-small mr-2"></div>}
                    <div>Update Blog </div>
                  </button>
                </div>
              </>
            </div>
            <div className="col-md-6">
              {sectionLoader ? (
                <div className="loader"></div>
              ) : (
                <>
                  <div className="mx-4 mt-3">
                    <div className="">
                      {data?.image_url && (
                        <div style={{ zIndex: 1 }}>
                          <img
                            src={data?.image_url}
                            alt=""
                            style={{
                              height: "200px",
                              width: "45vw",
                              borderRadius: "18px",
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          marginTop: "10px",
                          zIndex: 10,
                        }}
                        className="bg-white text-center"
                      >
                        <h2>
                          <strong>{data?.title}</strong>
                        </h2>
                      </div>
                      <div className="mx-5">
                        <div className="d-flex justify-content-between mt-5 ">
                          <div className="d-flex ">
                            {data?.author_details?.author_image && (
                              <>
                                <img
                                  src={data?.author_details?.author_image}
                                  alt=""
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                  className="mr-2"
                                />{" "}
                              </>
                            )}
                            <span className="">
                              <b>{data?.author_details?.author_name}</b>
                            </span>
                          </div>
                          <div>
                            <b>{data?.location}</b>{" "}
                            {data?.status && (
                              <span className="bg-secondary ml-2 text-white px-2 rounded mt-2">
                                {data?.status}
                              </span>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="my-2">{description}</div>
                        <hr />

                        <div className="mt-4">
                          {sections.map((section, index) => (
                            <div key={index} className="mt-2">
                              {section.section_type === "editor" && (
                                <div style={{ position: "relative" }}>
                                  <div
                                    className="blog_section mt-2"
                                    dangerouslySetInnerHTML={{
                                      __html: section.section_data_mapping,
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: -10,
                                      right: -5,
                                      zIndex: 100,
                                      border: "",
                                    }}
                                    className=""
                                  >
                                    <img
                                      src="/images/edit.svg"
                                      alt=""
                                      onClick={() => {
                                        handleEditSection(index);
                                        window.scrollTo(0, 0);
                                      }}
                                    />
                                    <img
                                      src="/images/cross_modal.svg"
                                      alt=""
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setSDeleteSectionId(section?.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {section?.section_type === "image" && (
                                <>
                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={section?.section_data_mapping}
                                      alt=""
                                      className="mt-2"
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                      }}
                                    >
                                      <img
                                        src="/images/edit.svg"
                                        alt=""
                                        onClick={() => {
                                          handleEditSection(index);
                                          window.scrollTo(0, 0);
                                        }}
                                      />
                                      <img
                                        src="/images/cross_modal.svg"
                                        alt=""
                                        onClick={() => {
                                          setDeleteModal(true);
                                          setSDeleteSectionId(section?.id);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              {section?.section_type == "advertisement" && (
                                <div style={{ position: "relative" }}>
                                  <div className="d-flex">
                                    {section?.section_data_mapping?.length >
                                      0 &&
                                      section?.section_data_mapping?.map(
                                        (property, index) => (
                                          <div
                                            className="bg-secondary rounded text-white px-2 mx-1 mt-3"
                                            key={index}
                                          >
                                            {property.property_name}
                                          </div>
                                        )
                                      )}
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: -2,
                                      right: 0,
                                    }}
                                  >
                                    <img
                                      src="/images/edit.svg"
                                      alt=""
                                      onClick={() => {
                                        handleEditSection(index);
                                        window.scrollTo(0, 0);
                                      }}
                                    />
                                    <img
                                      src="/images/cross_modal.svg"
                                      alt=""
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setSDeleteSectionId(section?.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {deleteSectionId && openDeleteModal && (
        <>
          <Modal
            show={openDeleteModal}
            centered
            size="md"
            className="common_modal"
          >
            <Modal.Header>
              <b>Delete Section</b>
              <img
                src="/images/cross_modal.svg"
                className="cursor-pointer"
                alt="Close Icon"
                onClick={() => {
                  setDeleteModal(false);
                }}
              />
            </Modal.Header>
            <Modal.Body>Are you sure want to delete the Section ?</Modal.Body>
            <Modal.Footer>
              <button className="basic-button" disabled={updateLoding} onClick={handleDeleteSection}>
                Delete Section
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      {openUpdateBlog && (
        <UploadBlog
          openModal={openUpdateBlog}
          setOpenModal={setOpenUpdateBlog}
          data={data}
        />
      )}
    </>
  );
};

export default withDefaultDashBoardLayout(EditBlog);
